// -----------------   Server URL      -------------------
export const BaseUrl = "https://stage.api.visceral.io";
// export const SiteUrl = "https://stage.visceral.io/";

// ---------------     Local URL       -----------------------
// export const process.env.REACT_APP_BaseUrl = "http://192.168.0.124:3002/";

// ---------------     local    ----------------
// export const process.env.REACT_APP_BaseUrl = "http://localhost:5000/";
// export const SiteUrl = "http://localhost:3000";
