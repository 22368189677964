import { Link } from "react-router-dom";
import React from 'react';

const SquareBox = ({ children, href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <div
      style={{
        width: 200,
        height: 200,
        background: 'linear-gradient(to right, #6366f1, #8B5CF6, #D946EF)',
        border: '1px solid black',
        display: 'inline-block',
        margin: 30,
        cursor: 'pointer',
        textAlign: 'center',
        verticalAlign: 'top',
      }}
    >
      {children}
    </div>
  </a>
);

const Arrow = () => (
  <div
    style={{
      display: 'inline-block',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderLeft: '20px solid #6366f1',
      margin: '0 20px',
      fontSize: 24,
      color: '#6366f1',
      position: 'relative',
      top: 100, // adjust this value to move the arrow up or down
    }}
  />
);

const GameProductionPhases = () => {
  return (
    <div style={{ position: 'relative' }}>
      <h1 style={{
        fontSize: 36,
        fontWeight: 'bold',
        marginBottom: 20,
        background: '#080533',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }}>&nbsp;&nbsp;AI TOOLS FOR DEMO</h1>
      <h2 style={{ fontSize: 24, marginBottom: 10, color: '#33CC33' }}></h2>
      <SquareBox href="https://primitivdashboard.visceral.io/">
        <h4 style={{ color: '#080533' }}>Research</h4>
      </SquareBox>
      <Arrow />
      <SquareBox href="" style={{ marginLeft: 20 }}>
        <h4 style={{ color: '#080533' }}>Design and Build</h4>
      </SquareBox>
      <Arrow />
      <SquareBox href="https://primitiv.visceral.io" style={{ marginLeft: 20 }}>
        <h4 style={{ color: '#080533' }}>Publish</h4>
      </SquareBox>
      <Arrow />
      <SquareBox href="https://lookerstudio.google.com/reporting/69b078e1-4403-4af1-a4e9-e2d1c50f9c86" style={{ marginLeft: 20, display: 'inline-block' }}>
        <h4 style={{ color: '#080533' }}>Live Ops</h4>
      </SquareBox>
      <>
      <footer className="footer pt-5 pb-4 pb-lg-5 mt-2 mt-md-0">
        <div className="container pt-lg-4">
          <div className="row pb-5">
            <div className="col-lg-4 col-md-6">
              {/* <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
              <img src="/favicon.jpg" width={35} alt="Visceral" className="shadow rounded-3" />
              Visceral
            </div> */}
              <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                <img
                  src="/Visceral-Black.svg"
                  width={100}
                  alt="Visceral"
                  className=" rounded-3"
                />
              </div>
              {/* <p className="fs-sm pb-lg-3 mb-4">
                Visceral helps Train, Test and Hire for next generation 3D
                platforms, starting with Unreal Engine.
              </p> */}
              {/* <form className="needs-validation" noValidate="">
              <label htmlFor="subscr-email" className="form-label">
                Subscribe to our newsletter
              </label>
              <div className="input-group">
                <input
                  type="email"
                  id="subscr-email"
                  className="form-control rounded-start ps-5"
                  placeholder="Your email"
                  required=""
                />
                <i className="bx bx-envelope fs-lg text-muted position-absolute top-50 start-0 translate-middle-y ms-3 zindex-5" />
                <div className="invalid-tooltip position-absolute top-100 start-0">
                  Please provide a valid email address.
                </div>
                <button type="submit" className="btn btn-primary">
                  Subscribe
                </button>
              </div>
            </form> */}
            </div>
            <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
              <div id="footer-links" className="row">
                <div className="col-lg-4">
                  <h6 className="mb-2">
                    <a
                      href="#useful-links"
                      className="d-block text-dark dropdown-toggle d-lg-none py-2"
                      data-bs-toggle="collapse"
                    >
                      Useful Links
                    </a>
                  </h6>
                  <div
                    id="useful-links"
                    className="collapse d-lg-block"
                    data-bs-parent="#footer-links"
                  >
                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                      <li className="nav-item">
                        {/* <Link
                          to="/"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Home
                        </Link> */}
                      </li>
                      <li className="nav-item">
                        {/* <Link
                          to="/candidate?skills=&speciality=&experienceFrom=&experienceTo=&timeZone="
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Hire Now
                        </Link> */}
                      </li>
                      <li className="nav-item">
                        {/* <Link
                          to="/test"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Get Hired
                        </Link> */}
                      </li>
                      {/* <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        Blog
                      </a>
                    </li> */}
                    </ul>
                    <ul className="nav flex-column mb-2 mb-lg-0">
                      <li className="nav-item">
                        {/* <Link
                          to="/terms"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Terms &amp; Conditions
                        </Link> */}
                      </li>
                      <li className="nav-item">
                        {/* <Link
                          to="/privacy_policy"
                          className="nav-link d-inline-block px-0 pt-1 pb-2"
                        >
                          Privacy Policy
                        </Link> */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-3">
                  <h6 className="mb-2">
                    <a
                      href="#social-links"
                      className="d-block text-dark dropdown-toggle d-lg-none py-2"
                      data-bs-toggle="collapse"
                    >
                      {/* Socials */}
                    </a>
                  </h6>
                  <div
                    id="social-links"
                    className="collapse d-lg-block"
                    data-bs-parent="#footer-links"
                  >
                    <ul className="nav flex-column mb-2 mb-lg-0">
                      {/* <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        Facebook
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        LinkedIn
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        Twitter
                      </a>
                    </li> 
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link d-inline-block px-0 pt-1 pb-2"
                      >
                        Behance
                      </a>
                    </li>*/}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                  <h6 className="mb-2">Contact Us</h6>
                  <a href="mailto:email@example.com" className="fw-medium">
                    info@visceral.io
                  </a>
                </div>
              </div>
            </div>
          </div>
          <p className="fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
            © All rights reserved. Made by
            <a
              className="nav-link d-inline-block p-0 ps-1"
              href="/"
              target="_blank"
              rel="noopener"
            >
              Visceral
            </a>
          </p>
        </div>
      </footer>

      <a href="#top" className="btn-scroll-top" data-scroll="">
        <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">
          Top
        </span>
        <i className="btn-scroll-top-icon bx bx-chevron-up" />
      </a>
    </>
      </div>
  );
};

export default GameProductionPhases;